import { CustomerPaymentInfos, DEFAULT_CUSTOMER_PAYMENT_INFOS } from "./Customer";
import { Receipt } from "./Receipt";
import { notNullish } from "../utils/array";
import { generateSearchableSubstrings } from "../utils/generateSearchableSubstrings";

export enum OfferStatus {
    DRAFT = "DRAFT",
    OPEN = "OPEN",
    APPROVED = "APPROVED",
    DECLINED = "DECLINED",
}

export const getOfferStatusName = (status: OfferStatus) => {
    switch (status) {
        case OfferStatus.DRAFT:
            return "Entwurf";
        case OfferStatus.OPEN:
            return "Offen";
        case OfferStatus.APPROVED:
            return "Angenommen";
        case OfferStatus.DECLINED:
            return "Abgelehnt";
    }
};
export class Offer extends Receipt {
    public offerStatus: OfferStatus;
    public createdBillId: string | null;
    public paymentInfos: CustomerPaymentInfos;
    public grossPrice: number;

    public _searchableSubstrings: string[];

    constructor(initialValues?: Partial<Offer>) {
        super(initialValues);
        this.offerStatus = initialValues?.offerStatus ?? OfferStatus.DRAFT;
        this.createdBillId = initialValues?.createdBillId ?? null;
        this.paymentInfos = {
            ...DEFAULT_CUSTOMER_PAYMENT_INFOS,
            ...initialValues?.paymentInfos,
        };
        this._searchableSubstrings = initialValues?._searchableSubstrings ?? [];
        this.grossPrice = initialValues?.grossPrice ?? 0;
    }
}

export function generateSearchableSubstringsForOffer(offer: Partial<Offer>): string[] {
    const searchableOfferAttributes: string[] = [
        offer.receiptNumber?.toString(),
        offer.billingAddress?.name.substring(0, 50),
        offer.contactPerson ?? "",
        offer.contactEmail ?? "",
    ].filter(notNullish);

    return generateSearchableSubstrings(searchableOfferAttributes);
}
