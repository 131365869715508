import { computeArea, computeDistanceBetween, computeLength } from "spherical-geometry-js";
import { Vertex } from "../model/Vertex";

export function computeShapeToAreaHa(shape: Vertex[]): number {
    const area = computeArea(shape);
    return area / 10_000;
}

export function computeShapeToLengthMeters(shape: Vertex[]): number {
    if (shape.length < 2) {
        return 0;
    }
    return computeLength(shape);
}

export function computeShapeToSqureMeters(shape: Vertex[]): number {
    return computeArea(shape);
}

export function computeDistanceBetweenInMeters(pointA: Vertex, pointB: Vertex): number {
    return computeDistanceBetween(pointA, pointB);
}
