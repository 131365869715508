import { Customer } from "./Customer";
import { CustomerTag } from "./CustomerTag";
import { Service } from "./services/Service";
import { AnyPriceStructure, ServicePriceBlockBase } from "./services/ServicePriceBlocks";

export class PriceAgreement {
    archived: boolean;
    id: string;
    customerTarget: AnyCustomerTarget;
    priceTarget: AnyPriceAgreementPrice;
    /**
     * Date in YYYY-MM-DD format.
     */
    start: string;
    /**
     * Date in YYYY-MM-DD format.
     */
    end: string;
    note: string;

    constructor(initialValues?: Partial<PriceAgreement>) {
        this.archived = initialValues?.archived ?? false;
        this.id = initialValues?.id ?? "";
        this.customerTarget = initialValues?.customerTarget ?? {
            type: CustomerTargetType.SPECIFIC_CUSTOMERS,
            customerIds: [],
        };
        this.priceTarget = initialValues?.priceTarget ?? {
            type: PriceAgreementPriceType.SERVICE,
            serviceId: "",
            overrides: [],
        };
        this.start = initialValues?.start ?? "";
        this.end = initialValues?.end ?? "";
        this.note = initialValues?.note ?? "";
    }
}

export enum CustomerTargetType {
    SPECIFIC_CUSTOMERS = "CustomerTargetType_SPECIFIC_CUSTOMERS",
    CUSTOMER_TAGS = "CustomerTargetType_CUSTOMER_TAGS",
}

type SpecificCustomerTarget = {
    type: CustomerTargetType.SPECIFIC_CUSTOMERS;
    customerIds: Customer["id"][];
};

type CustomerTagTarget = {
    type: CustomerTargetType.CUSTOMER_TAGS;
    tagIds: CustomerTag["id"][];
};

type AnyCustomerTarget = SpecificCustomerTarget | CustomerTagTarget;

export enum PriceAgreementPriceType {
    SERVICE = "PriceAgreementPriceType_SERVICE",
}

export type ServicePrice = {
    type: PriceAgreementPriceType.SERVICE;
    serviceId: Service["id"];
    overrides: ServicePriceBlockOverride[];
};

type AnyPriceAgreementPrice = ServicePrice;

export type ServicePriceBlockOverride = {
    priceBlockId: ServicePriceBlockBase["id"];
    priceOverride: AnyPriceStructure;
};
