import { Describe, array, boolean, is, number, object, string, type } from "superstruct";
import { Vertex, VertexStruct } from "./Vertex";
import { computeShapeToLengthMeters } from "../utils/computeShapeUtilsModel";

export class Track {
    public static DEFAULT_COLOR = "#39b54a";

    public id: string;
    public archived: boolean;
    public customerId: string;
    public shape: Vertex[];
    public name: string;
    public color: string;
    /**
     * length in meters
     */
    public length: number;
    public directionEnabled: boolean;

    constructor(initialValues?: Partial<Track>) {
        this.id = initialValues?.id ?? "";
        this.archived = initialValues?.archived ?? false;
        this.customerId = initialValues?.customerId ?? "";
        this.shape = initialValues?.shape ?? [];
        this.name = initialValues?.name ?? "";
        this.color = initialValues?.color ?? Track.DEFAULT_COLOR;
        this.length = initialValues?.length || computeShapeToLengthMeters(initialValues?.shape ?? []);
        this.directionEnabled = initialValues?.directionEnabled ?? false;
    }
}

export const TrackStruct = object({
    id: string(),
    archived: boolean(),
    customerId: string(),
    shape: array(VertexStruct),
    name: string(),
    color: string(),
    length: number(),
    directionEnabled: boolean(),
}) satisfies Describe<Track>;

const IsTrackStruct = type({
    length: number(),
    shape: array(),
    customerId: string(),
    archived: boolean(),
});

export function isTrack(candidate: any): candidate is Track {
    return is(candidate, IsTrackStruct);
}
