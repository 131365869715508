import dayjs from "dayjs";
import { CostAccount } from "./CostAccount";
import { Currency } from "./Currency";
import { IncomingReceipt } from "./IncomingReceipt";
import { OperatingUnit } from "./OperatingUnit";

export class IncomingBill extends IncomingReceipt {
    public costAccountId: CostAccount["id"] | null;
    public currency: Currency;
    public dueDate: string;
    public grossAmount: number;
    /**
     * format: ISO datetime
     */
    public csvExportDate: string | null;
    public lineItems: LineItem[];
    public netAmount: number;
    public operatingUnitId: OperatingUnit["id"];
    public totalTaxAmount: number;
    public vat: Vat[];

    constructor(initialValues?: Partial<IncomingBill>) {
        super(initialValues);
        this.costAccountId = initialValues?.costAccountId ?? null;
        this.currency = initialValues?.currency ?? Currency.EUR;
        this.date = initialValues?.date ?? dayjs().format("YYYY-MM-DD");
        this.dueDate = initialValues?.dueDate ?? "";
        this.grossAmount = initialValues?.grossAmount ?? 0;
        this.csvExportDate = initialValues?.csvExportDate ?? null;
        this.lineItems = initialValues?.lineItems ?? [];
        this.netAmount = initialValues?.netAmount ?? 0;
        this.operatingUnitId = initialValues?.operatingUnitId ?? "";
        this.totalTaxAmount = initialValues?.totalTaxAmount ?? 0;
        this.vat = initialValues?.vat ?? [];
    }
}

type Vat = {
    amount: number;
    rate: number;
    occurrence: number;
};

type LineItem = {
    amount: number;
    description: string;
    quantity: number;
    unitPrice: number;
    unit: string;
};

export function isIncomingBill(receipt: any): receipt is IncomingBill {
    const receiptToCheck = receipt as IncomingBill;
    return typeof receiptToCheck.grossAmount === "number" && typeof receiptToCheck.netAmount === "number";
}
