// home
export const LANDING = "/";
export const HOME = "/home";
export const BANKING_REDIRECT = "/bankingRedirect";

// auth
export const SIGNIN = "/signin";
export const SIGNUP = "/signup";

// orders
export const ORDERS = "/orders";
export const ORDER_CREATE = `${ORDERS}/create`;
export const ORDER_DETAILS = `${ORDERS}/detail/:orderId`;
export const ORDERS_ARCHIVE = `${ORDERS}/archive`;
export const ORDER_SETTINGS = `${ORDERS}/settings`;
export const RENTAL_ORDERS = `${ORDERS}/rentals`;
export const RENTAL_ORDER_CREATE = `${RENTAL_ORDERS}/create`;
export const RENTAL_ORDER_DETAILS = `${RENTAL_ORDERS}/detail/:rentalId`;
export const ORDER_DISPOSITION = `${ORDERS}/disposition`;

// analyse
export const ANALYSIS_REVENUE = "/analysis/revenue";

// cost Account
export const COST_ACCOUNTS = "/costAccounts";

// receipts
export const RECEIPTS = "/receipts";
// bills
export const BILLS = `${RECEIPTS}/bills`;
export const BILL_SETTINGS = `${BILLS}/settings`;
export const BILL_CREATE = `${BILLS}/create`;
export const BILL_DETAILS = `${BILLS}/detail/:billId`;
export const BILL_ARCHIVE = `${BILLS}/archive`;

// invoices (incoming invoices)
export const INCOMING_BILLS = `${RECEIPTS}/incomingBills`;
export const INCOMING_BILLS_DETAILS = `${INCOMING_BILLS}/detail/:incomingBillId`;

// bill reminders
export const BILL_REMINDERS = `${RECEIPTS}/billReminders`;
export const BILL_REMINDERS_FOR_BILL = `${BILL_DETAILS}/billReminders`;
export const BILL_REMINDER_DETAILS = `${BILL_REMINDERS_FOR_BILL}/:billReminderId`;
// bill cancellation
export const BILL_CANCELLATION = `${BILL_DETAILS}/cancellation`;
// delivery notes
export const DELIVERY_NOTES = `${RECEIPTS}/deliveryNotes`;
export const DELIVERY_NOTES_ARCHIVE = `${DELIVERY_NOTES}/archive`;
export const DELIVERY_NOTES_CREATE = `${DELIVERY_NOTES}/create`;
export const DELIVERY_NOTES_DETAIL = `${DELIVERY_NOTES}/detail/:noteId`;
export const DELIVERY_NOTES_SETTINGS = `${DELIVERY_NOTES}/settings`;
// offers
export const OFFERS = `${RECEIPTS}/offers`;
export const OFFERS_CREATE = `${OFFERS}/create`;
export const OFFERS_DETAIL = `${OFFERS}/detail/:offerId`;
export const OFFERS_SETTINGS = `${OFFERS}/settings`;
export const OFFERS_ARCHIVE = `${OFFERS}/archive`;
// credit notes
export const CREDIT_NOTES = `${RECEIPTS}/creditNotes`;
export const CREDIT_NOTES_ARCHIVE = `${CREDIT_NOTES}/archive`;
export const CREDIT_NOTES_CREATE = `${CREDIT_NOTES}/create`;
export const CREDIT_NOTES_DETAIL = `${CREDIT_NOTES}/detail/:creditNoteId`;
export const CREDIT_NOTES_SETTINGS = `${CREDIT_NOTES}/settings`;
// employees map
export const EMPLOYEES_MAP = "/employeesMap";

// organization
export const ORGANIZATION = "/organization";
export const ORGANIZATION_CUSTOMERS = `${ORGANIZATION}/customers`;
export const ORGANIZATION_CUSTOMER_TAGS = `${ORGANIZATION_CUSTOMERS}/tags`;
export const ORGANIZATION_CUSTOMER_MAP = `${ORGANIZATION_CUSTOMERS}/map`;
export const ORGANIZATION_CUSTOMERS_SETTINGS = `${ORGANIZATION_CUSTOMERS}/settings`;
export const ORGANIZATION_CUSTOMERS_DETAILS = `${ORGANIZATION_CUSTOMERS}/details/:customerId`;
export const ORGANIZATION_PRICE_AGREEMENTS = `${ORGANIZATION}/price-agreements`;
export const ORGANIZATION_PRICE_AGREEMENT_DETAILS = `${ORGANIZATION_PRICE_AGREEMENTS}/details/:agreementId`;
export const ORGANIZATION_LOADING_POINTS = `${ORGANIZATION}/loadingPoints`;
export const ORGANIZATION_EMPLOYEES = `${ORGANIZATION}/employees`;
export const ORGANIZATION_EMPLOYEES_TIME_TRACKING = `${ORGANIZATION_EMPLOYEES}/time-trackings`;
export const ORGANIZATION_EMPLOYEES_DETAILS = `${ORGANIZATION_EMPLOYEES}/details/:employeeId`;
export const ORGANIZATION_MACHINES = `${ORGANIZATION}/machines`;
export const ORGANIZATION_MACHINE_DETAILS = `${ORGANIZATION_MACHINES}/details/:machineId`;
export const ORGANIZATION_MACHINE_HISTORY = `${ORGANIZATION_MACHINES}/history/:machineId`;
export const ORGANIZATION_SERVICES = `${ORGANIZATION}/services`;
export const ORGANIZATION_SERVICES_DETAILS = `${ORGANIZATION_SERVICES}/details/:serviceId`;
export const ORGANIZATION_SERVICES_CATALOG = `${ORGANIZATION_SERVICES}/catalog`;
export const ORGANIZATION_RENTAL_SERVICES = `${ORGANIZATION}/rentals`;
export const ORGANIZATION_RESOURCES = `${ORGANIZATION}/resources`;
export const ORGANIZATION_RESOURCES_TAGS = `${ORGANIZATION_RESOURCES}/tags`;
export const ORGANIZATION_RESOURCES_HISTORY = `${ORGANIZATION_RESOURCES}/history`;
export const ORGANIZATION_RESOURCES_DETAILS = `${ORGANIZATION_RESOURCES}/details/:resourceId`;
export const ORGANIZATION_GASSTATIONS = `${ORGANIZATION}/gasStations`;
export const ORGANIZATION_SUPPLIERS = `${ORGANIZATION}/suppliers`;
export const ORGANIZATION_SUPPLIERS_DETAILS = `${ORGANIZATION_SUPPLIERS}/details/:supplierId`;
export const ORGANIZATION_SUPPLIERS_SETTINGS = `${ORGANIZATION_SUPPLIERS}/settings`;
export const ORGANIZATION_COMPANY = `${ORGANIZATION}/company`;

// driver pages
export const TASKS = "/tasks";
export const TASK_DETAILS = "/tasks/:orderId";
export const INTERNAL_WORK = "/internalWork";
export const TIME_TRACKING = "/timeTracking";

// other pages
export const ACCOUNT = "/account";
export const STRIPE_ERROR = "/stripe-error";
export const CONTRACTORS = "/contractors";
export const CONTRACTOR_DETAILS = `${CONTRACTORS}/:contractorId`;
export const UPDATES_INFO = "/updates";
export const UPDATES_INFO_DETAIL = `${UPDATES_INFO}/detail/:updateId`;
export const SETTINGS = "/settings";
export const REPORTS = "/reports";

// public pages
export const ORDERS_PROGRESS_SHARING = "/ordersProgressSharing";
export const MAP_STRUCTURES_SHARING = "/mapStructuresSharing";
export const MAIL_ACTION = "/mailAction";

// unused
export const PLANNING = "/planning";
export const NEWS = "/news";
export const TEST = "/test";
export const ADMIN = "/admin";

// projects
export const PROJECTS = "/projects";
export const PROJECT_DETAILS = "/projects/:projectId";

// toll
export const TOLL = "/toll";
