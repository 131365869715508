import dayjs from "dayjs";
import { Customer } from "./Customer";
import { Order } from "./Order";
import { PriceAgreement } from "./PriceAgreement";

export class PriceAgreementSnapshot {
    id: string;
    orderId: Order["id"];
    customerId: Customer["id"];
    /**
     * ISO date time
     */
    timestamp: string;
    snapshot: PriceAgreement;

    constructor(initialValues?: Partial<PriceAgreementSnapshot>) {
        this.id = initialValues?.id ?? "";
        this.timestamp = initialValues?.timestamp ?? dayjs().toISOString();
        this.customerId = initialValues?.customerId ?? "";

        this.orderId = initialValues?.orderId ?? "";
        this.snapshot = initialValues?.snapshot ?? new PriceAgreement();
    }
}
